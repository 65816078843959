import React from 'react';
import Lockers from "./pages/lockers";

type AppProps = {
  getLocationNameFromComponent: Function
}
function App({getLocationNameFromComponent}: AppProps) {
  return (
    <div className="App">
      <Lockers 
      getLocationNameFromComponent={getLocationNameFromComponent}/>
    </div>
  );
}

export default App;
