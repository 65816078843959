import {send} from "../modules/request";


const getLockers = async (data: {} = {}) => {
    return await send({
        method: "post",
        url: 'https://ecommerce.fancourier.ro/api/lockers/get-lockers-from-map',
        data: data
    });
}

const getCounties = async (data: {} = {}) => {
    return await send({
        method: "post",
        url: 'https://ecommerce.fancourier.ro/api/get-counties',
        data: data,
    });
}

const getLocalities = async (data: {} = {}) => {
    return await send({
        method: "post",
        url: 'https://ecommerce.fancourier.ro/api/get-localities',
        data: data,
    });
}

export default {
    getLockers,
    getCounties,
    getLocalities
}