import React from 'react';
import {createRoot} from 'react-dom/client';
import './scss/index.scss';
import App from './App';


const root = createRoot(
    document.getElementById('root') as HTMLElement
);
const LoadMap = (getLocationNameFromComponent: Function) => {
    root.render(
        <React.StrictMode>
            <App
                getLocationNameFromComponent={getLocationNameFromComponent}/>
        </React.StrictMode>
    );
}

(window as any).LoadMap = LoadMap;

