import axios from "axios";
import {AxiosRequest} from "../interfaces/axios/request";

export const send = async (
    {
        data = {},
        params = {},
        method,
        url,
        headers = {},
    }: AxiosRequest) => {

    try {
        let serverResponse = await axios({
            method: method,
            params: params,
            url: url,
            data: data,
            headers: headers
        });

        return {
            status: 200,
            data: serverResponse.data,
            errorMessage: "",
            fieldsErrorMessages: {}
        }

    } catch (error: any) {
        if (error.response) {
            return {
                status: error.response.status,
                data: error.response.data.data,
                errorMessage: error.response.data.message,
                fieldsErrorMessages: error.response.data.errors
            }

        }
        return {
            status: 500,
            data: {},
            errorMessage: "A aparut o eroare, incearca mai tarziu",
            fieldsErrorMessages: {}
        };
    }
}

export const sendGeolocation = async (
    {
        data = {},
        params = {},
        method,
        url,
        headers = {},
    }: AxiosRequest) => {

    try {
        let serverResponse = await axios({
            method: method,
            params: params,
            url: url,
            data: data,
            headers: headers
        });
        return {
            status: 200,
            data: serverResponse.data[0],
            errorMessage: "",
            fieldsErrorMessages: {}
        }

    } catch (error: any) {
        if (error.response) {
            return {
                status: error.response.status,
                data: error.response.data.data,
                errorMessage: error.response.data.message,
                fieldsErrorMessages: error.response.data.errors
            }

        }
        return {
            status: 500,
            data: {},
            errorMessage: "A aparut o eroare, incearca mai tarziu",
            fieldsErrorMessages: {}
        };
    }
}
