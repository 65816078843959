import React, {useCallback, useEffect, useRef} from "react";
import {AttributionControl, MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import {MapMarkerIcon} from "./mapMarkerIcon";
import {LockersData} from "../interfaces/lockers/lockers";
import {Button, Grid} from "@mui/material";

interface SelectValueInterface {
    name: string,
    id: number
}

type LockerMapProps = {
    lockersData: LockersData | Array<any>
    mapLatitude: number;
    setMapLatitude: Function,
    mapLongitude: number;
    setMapLongitude: Function,
    mapZoom: number,
    setMapZoom: Function,
    setSelectedCounty: Function,
    setSelectedLocality: Function
    getLocalities: Function,
    getLockersData: Function
    setSelectedLocation: Function,
    isOpenPopup: boolean
    selectedLocation: SelectValueInterface | null,
    setSelectedLocationDetails: Function,
}
export default function LockersMap(
    {
        lockersData,
        mapLatitude,
        setMapLatitude,
        mapLongitude,
        setMapLongitude,
        mapZoom,
        setMapZoom,
        setSelectedCounty,
        setSelectedLocality,
        getLocalities,
        getLockersData,
        setSelectedLocation,
        isOpenPopup,
        selectedLocation,
        setSelectedLocationDetails
    }: LockerMapProps
) {
    const markerRefs = useRef<any>({});

    const handleSelectLocation = useCallback((event: React.MouseEvent<HTMLButtonElement>, locker: any) => {
        setSelectedCounty({name: locker.countyName, id: locker.countyId});
        getLocalities({county: locker.countyName});
        setSelectedLocality({name: locker.localityName, id: locker.localityId});
        getLockersData(locker.countyName, locker.localityName);
        setSelectedLocation({name: locker.name, id: locker.id});
        setMapLatitude(locker.latitude);
        setMapLongitude(locker.longitude);
        setMapZoom(14);
        setSelectedLocationDetails(locker);
    }, [getLocalities, getLockersData, setMapLatitude, setMapLongitude, setMapZoom, setSelectedCounty, setSelectedLocality, setSelectedLocation, setSelectedLocationDetails]);

    useEffect(() => {
        const markerToOpen = markerRefs.current[selectedLocation?.id || 0];
        if (markerToOpen && isOpenPopup) {
            markerToOpen.openPopup();
        }
    }, [isOpenPopup, selectedLocation])
    const popupContent = (locker: any) => {
        return (
            <Grid container spacing={1}
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: "center",
                      textAlign: "center"
                  }}>
                <Grid item xs={12}>
                    <b>{locker.name}</b>
                </Grid>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{my: 1, maxWidth: '8rem'}}
                    onClick={(event) => handleSelectLocation(event, locker)}>
                    Alege locatia
                </Button>
                <Grid item xs={10}>
                    <b>Adresa: </b>
                    <span>{locker.address}</span>
                </Grid>
                <Grid item xs={10}>
                    <b>Reper: </b>
                    <span>{locker.locationReference}</span>
                </Grid>
                <Grid item xs={12}>
                    <b>Program</b>
                </Grid>
                <Grid item xs={6}>
                    Luni-Vineri: {locker.schedule}
                </Grid>
                <Grid item xs={6}>
                    Sambata: {locker.schedule}
                </Grid>
                <Grid item xs={6}>
                    Duminica: {locker.schedule}
                </Grid>
            </Grid>
        )
    }

    //Component to change map coordinates and details, mandatory
    function ChangeMapView() {
        const map = useMap();
        map.setView([mapLatitude, mapLongitude], mapZoom);
        return null;
    }

    return (
        <React.Fragment>
            <MapContainer
                style={{height: 480, width: "100%"}}
                center={[mapLatitude, mapLongitude]} zoom={mapZoom}
                attributionControl={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AttributionControl position="bottomright" prefix=""/>
                {lockersData.map((entry, index) => {
                    return (
                        <Marker
                            ref={(marker) => {
                                markerRefs.current[entry.id] = marker;
                            }}
                            key={index}
                            position={[entry.latitude, entry.longitude]}
                            icon={MapMarkerIcon}>
                            <Popup>
                                {popupContent(entry)}
                            </Popup>
                        </Marker>
                    )
                })}
                <ChangeMapView/>
            </MapContainer>
        </React.Fragment>
    )
}