import React, {useCallback, useEffect, useRef, useState} from "react";
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import LockersService from "../services/lockers";
import {Locker, LockersData} from "../interfaces/lockers/lockers";

interface SelectValueInterface {
    name: string,
    id: number
}

type LockersMapFiltersProps = {
    getLockersData: (county: string | null | undefined, locality: string | null | undefined) => {},
    setLockersData: Function,
    getGeolocation: Function,
    selectedCounty: SelectValueInterface | null,
    setSelectedCounty: Function,
    selectedLocality: SelectValueInterface | null,
    setSelectedLocality: Function,
    localities: SelectValueInterface[],
    setLocalities: Function,
    getLocalities: Function,
    selectedLocation: SelectValueInterface | null
    setSelectedLocation: Function,
    lockersData: LockersData | Array<any>,
    setMapLatitude: Function,
    setMapLongitude: Function,
    setMapZoom: Function,
    setIsOpenPopup: Function,
    selectedLocationDetails: Locker,
    setSelectedLocationDetails: Function,
    getLocationNameFromComponent: Function,
    setIsModalOpen: Function,
}

export default function LockersMapFilters(
    {
        getLockersData,
        setLockersData,
        getGeolocation,
        selectedCounty,
        setSelectedCounty,
        selectedLocality,
        setSelectedLocality,
        localities,
        setLocalities,
        getLocalities,
        selectedLocation,
        setSelectedLocation,
        lockersData,
        setMapLatitude,
        setMapLongitude,
        setMapZoom,
        setIsOpenPopup,
        selectedLocationDetails,
        setSelectedLocationDetails,
        getLocationNameFromComponent,
        setIsModalOpen,
    }: LockersMapFiltersProps
) {
    const countyAutocompleteRef = useRef<any>(null);
    const localityAutocompleteRef = useRef<any>(null);
    const selectedLocationAutocompleteRef = useRef<any>(null);

    const [counties, setCounties] = useState<SelectValueInterface[]>([{name: "Alegeti un judet", id: 0}]);

    const countyAutocomplete = countyAutocompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    const localityAutocomplete = localityAutocompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    const selectedLocationAutocomplete = selectedLocationAutocompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];

    useEffect(() => {
        setLockersData(() => getLockersData(
                selectedCounty?.name,
                selectedLocality?.name,
            )
        );
        LockersService.getCounties()
            .then(response => {
                    if (response.status !== 200) {
                        return;
                    }
                    setCounties(response.data);
                }
            );
    }, []);

    // County Autocomplete Clear handler
    useEffect(() => {
        const handleCountyAutocompleteClear = () => {
            let selectedCountyReset = {name: '', id: 0};
            let selectedLocalityReset = {name: '', id: 0};
            let localities: Array<any> = [{name: 'Alegeti o localitate', id: 0}];

            setSelectedCounty(selectedCountyReset);
            setSelectedLocality(selectedLocalityReset);
            setLocalities(localities);
            setLockersData(() => getLockersData(selectedCountyReset?.name, selectedLocalityReset?.name));
            getGeolocation(selectedCountyReset?.name, selectedLocalityReset?.name);
        };
        !!countyAutocomplete && countyAutocomplete.addEventListener("click", handleCountyAutocompleteClear);

        return () => {
            !!countyAutocomplete && countyAutocomplete.removeEventListener('click', handleCountyAutocompleteClear)
        }
    }, [countyAutocomplete, selectedCounty]);

    // Locality Autocomplete Clear handler
    useEffect(() => {
        const handleLocalityAutocompleteClear = () => {
            let selectedLocalityReset = {name: '', id: 0};
            setSelectedLocality(selectedLocalityReset);
            setLockersData(() => getLockersData(selectedCounty?.name, selectedLocalityReset?.name));
            getGeolocation(selectedCounty?.name, selectedLocalityReset?.name);
        };
        !!localityAutocomplete && localityAutocomplete.addEventListener("click", handleLocalityAutocompleteClear);

        return () => {
            !!localityAutocomplete && localityAutocomplete.removeEventListener('click', handleLocalityAutocompleteClear)
        }
    }, [localityAutocomplete, selectedLocality]);

    // Selected Location Autocomplete Clear handler
    useEffect(() => {
        const handleSelectedLocationAutocompleteClear = () => {
            let selectedLocationReset = {name: '', id: 0};
            setSelectedLocation(selectedLocationReset);
            getGeolocation(selectedCounty?.name, selectedLocality?.name);
        };
        !!selectedLocationAutocomplete && selectedLocationAutocomplete.addEventListener("click", handleSelectedLocationAutocompleteClear);

        return () => {
            !!selectedLocationAutocomplete && selectedLocationAutocomplete.removeEventListener('click', handleSelectedLocationAutocompleteClear)
        }
    }, [selectedLocationAutocomplete, selectedLocation]);

    const onCountyChange = useCallback((selectedOption: string | null) => {
        let selectedLocalityReset = {name: '', id: 0};
        let selectedLocationReset = {name: '', id: 0};
        setSelectedLocality(selectedLocalityReset);
        getGeolocation(selectedOption, selectedLocalityReset?.name);
        getLocalities({county: selectedOption});
        // + call endpoint get data for maps
        setSelectedLocation(selectedLocationReset);
        setLockersData(() => getLockersData(selectedOption, selectedLocalityReset?.name));
    }, [getGeolocation, getLocalities, getLockersData, setLockersData, setSelectedLocality, setSelectedLocation]);

    const onLocalityChange = useCallback((selectedOption: string | null) => {
        // + call endpoint get data for maps
        setLockersData(() => getLockersData(selectedCounty?.name, selectedOption));
        let selectedLocationReset = {name: '', id: 0};
        setSelectedLocation(selectedLocationReset);
        getGeolocation(selectedCounty?.name, selectedOption);
    }, [getGeolocation, getLockersData, selectedCounty, setLockersData, setSelectedLocation]);

    const onLocationChange = useCallback((selectedOption: any) => {
        setSelectedCounty({name: selectedOption?.countyName, id: selectedOption?.countyId});
        getLocalities({county: selectedOption?.countyName});
        setSelectedLocality({name: selectedOption?.localityName, id: selectedOption?.localityId});
        setMapLatitude(selectedOption?.latitude);
        setMapLongitude(selectedOption?.longitude);
        setMapZoom(11);
        setIsOpenPopup(true);
        setSelectedLocationDetails(selectedOption);
    }, [getLocalities, setIsOpenPopup, setMapLatitude, setMapLongitude, setMapZoom, setSelectedCounty, setSelectedLocality, setSelectedLocationDetails]);

    const handleClickFinalize = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const locationDetails = {
            locationName: selectedLocation?.name,
            countyName: selectedLocationDetails?.countyName,
            localityName: selectedLocationDetails?.localityName
        }
        getLocationNameFromComponent(locationDetails);
        setIsModalOpen(false);
    }, [selectedLocation, selectedLocationDetails]);

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <Autocomplete
                    ref={countyAutocompleteRef}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // to remove
                    onChange={(event, option) => {
                        if (option?.id) {
                            setSelectedCounty(option);
                            onCountyChange(option.name);
                        } else {
                            setSelectedCounty(null);
                        }
                    }}
                    defaultValue={selectedCounty}
                    value={selectedCounty}
                    options={counties}
                    getOptionLabel={option => option.name}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Judet"
                            variant="standard"
                            name="county"
                        />
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    ref={localityAutocompleteRef}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // to remove
                    onChange={(event, option) => {
                        if (option?.id) {
                            setSelectedLocality(option);
                            onLocalityChange(option.name);
                        } else {
                            setSelectedLocality(null);
                        }
                    }}
                    defaultValue={selectedLocality}
                    value={selectedLocality}
                    options={localities}
                    getOptionLabel={option => option.name}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Localitate"
                            variant="standard"
                            name="locality"
                        />
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    ref={selectedLocationAutocompleteRef}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // to remove
                    onChange={(event, option) => {
                        if (option?.id) {
                            setSelectedLocation(option);
                            onLocationChange(option);
                        } else {
                            setSelectedLocation(null);
                        }
                    }}
                    defaultValue={selectedLocation}
                    value={selectedLocation}
                    options={lockersData}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Nume/Adresa"
                            variant="standard"
                            name="selectedLocation"
                        />
                    }
                    componentsProps={{
                        paper: {
                            sx: {
                                height: 350
                            }
                        }
                    }}
                />
            </Grid>
            {(selectedLocation?.id !== 0 && selectedLocation !== null) &&
                (
                    <Grid container spacing={2}
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: "center",
                              marginTop: 2,
                              textAlign: "center"
                          }}>
                        <Grid item xs={12}>
                            <b>{selectedLocationDetails?.name}</b>
                        </Grid>
                        <Grid item xs={10}>
                            <b>Adresa: </b>
                            <span>{selectedLocationDetails?.address}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <b>Reper: </b>
                            <span>{selectedLocationDetails?.locationReference}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <b>Program</b>
                        </Grid>
                        <Grid item xs={6}>
                            Luni-Vineri: {selectedLocationDetails?.schedule}
                        </Grid>
                        <Grid item xs={6}>
                            Sambata: {selectedLocationDetails?.schedule}
                        </Grid>
                        <Grid item xs={6}>
                            Duminica: {selectedLocationDetails?.schedule}
                        </Grid>
                        <Button
                            variant="contained"
                            sx={{my: 2, maxWidth: '10rem', marginLeft: 2}}
                            onClick={(event) => handleClickFinalize(event)}>
                            Finalizeaza
                        </Button>
                    </Grid>
                )
            }
        </React.Fragment>
    )
}