import {Box, Grid, IconButton, Modal, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, {useCallback, useState} from "react";
import LockersMap from "../components/lockersMap";
import LockersMapFilters from "../components/lockersMapFilters";
import LockersService from "../services/lockers";
import {Locker, LockersData} from "../interfaces/lockers/lockers";
import GeolocationService from "../services/geolocation";

type LockersComponentProps = {
    getLocationNameFromComponent: Function
}

interface SelectValueInterface {
    name: string,
    id: number
}

export default function Lockers({getLocationNameFromComponent}: LockersComponentProps) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
    (window as any).setFlagModal = (value: boolean) => setIsModalOpen(value);
    const [lockersData, setLockersData] = useState<LockersData | Array<any>>([]);

    const [mapLatitude, setMapLatitude] = useState<number>(46.2197);
    const [mapLongitude, setMapLongitude] = useState<number>(24.7964);
    const [mapZoom, setMapZoom] = useState<number>(6);
    const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

    const [localities, setLocalities] = useState<SelectValueInterface[]>([{name: "Alegeti o localitate", id: 0}]);
    const [selectedCounty, setSelectedCounty] = useState<{ name: string, id: number } | null>
    ({name: "", id: 0});
    const [selectedLocality, setSelectedLocality] = useState<{ name: string, id: number } | null>
    ({name: "", id: 0});
    const [selectedLocation, setSelectedLocation] = useState<{ name: string, id: number } | null>
    ({name: "", id: 0});
    const [selectedLocationDetails, setSelectedLocationDetails] = useState<Locker>({
        id: 0,
        name: '',
        countyId: 0,
        countyName: '',
        localityId: 0,
        localityName: '',
        address: '',
        latitude: 0,
        longitude: 0,
        locationReference: '',
        schedule: ''
    });

    const getLockersData = (county: string | null | undefined, locality: string | null | undefined) => {
        const requestPayload = {
            county: county || '',
            locality: locality || '',
        }
        LockersService.getLockers(requestPayload)
            .then((response) => {
                if (response.status !== 200) {
                    return;
                }
                setLockersData(response.data);
            })
        return lockersData;
    };

    const getGeolocation = useCallback((county: string | null | undefined, locality: string | null | undefined) => {
        const payload = {
            county: county,
            locality: locality
        }
        if (county === '' && locality === '') {
            setMapLatitude(46.2197);
            setMapLongitude(24.7964);
            setMapZoom(6);
        } else {
            GeolocationService.getGeolocation(payload).then((response) => {
                setMapLatitude(parseFloat(response.data.lat));
                setMapLongitude(parseFloat(response.data.lon))
                setMapZoom(11);
            })
        }
    }, [])

    const getLocalities = useCallback((payload: { county: string }) => {
        LockersService.getLocalities({county: payload.county})
            .then(response => {
                    if (response.status !== 200) {
                        return;
                    }
                    setLocalities(response.data);
                }
            );
    }, []);

    return (
        <React.Fragment>
            <Modal
                open={isModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modalBox">
                    <div className="modalBoxHeader">
                        <IconButton
                            sx={{pb: 2}}
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LockersMapFilters
                                getLockersData={getLockersData}
                                setLockersData={setLockersData}
                                getGeolocation={getGeolocation}
                                selectedCounty={selectedCounty}
                                setSelectedCounty={setSelectedCounty}
                                selectedLocality={selectedLocality}
                                setSelectedLocality={setSelectedLocality}
                                localities={localities}
                                setLocalities={setLocalities}
                                getLocalities={getLocalities}
                                selectedLocation={selectedLocation}
                                setSelectedLocation={setSelectedLocation}
                                lockersData={lockersData}
                                setMapLatitude={setMapLatitude}
                                setMapLongitude={setMapLongitude}
                                setMapZoom={setMapZoom}
                                setIsOpenPopup={setIsOpenPopup}
                                selectedLocationDetails={selectedLocationDetails}
                                setSelectedLocationDetails={setSelectedLocationDetails}
                                getLocationNameFromComponent={getLocationNameFromComponent}
                                setIsModalOpen={setIsModalOpen}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LockersMap
                                lockersData={lockersData}
                                mapLatitude={mapLatitude}
                                setMapLatitude={setMapLatitude}
                                mapLongitude={mapLongitude}
                                setMapLongitude={setMapLongitude}
                                mapZoom={mapZoom}
                                setMapZoom={setMapZoom}
                                setSelectedCounty={setSelectedCounty}
                                setSelectedLocality={setSelectedLocality}
                                getLocalities={getLocalities}
                                getLockersData={getLockersData}
                                setSelectedLocation={setSelectedLocation}
                                isOpenPopup={isOpenPopup}
                                selectedLocation={selectedLocation}
                                setSelectedLocationDetails={setSelectedLocationDetails}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </React.Fragment>
    )
}