import {sendGeolocation} from "../modules/request";

type GeolocationProps = {
    county: string | null | undefined,
    locality: string | null | undefined
}
const getGeolocation = async (data: GeolocationProps = {
    county: '',
    locality: '',
}) => {
    data.locality = data.locality === 'Bucuresti' ? '' : data.locality;
    const url = 'https://nominatim.openstreetmap.org/search.php?q=' +
        'Romania, ' + data.county + ', ' + data.locality +
        '&format=jsonv2&limit=1';
    return await sendGeolocation({
        method: "get",
        url: url,
    });
}


export default {
    getGeolocation,
}